<template>
  <VSSelectBase
    :id="props.id"
    :disabled="props.disabled"
    :readonly="props.readonly"
    :label="props.label"
    :description="props.description"
    :open="props.open"
    :search="props.search"
    :search-placeholder="props.searchPlaceholder"
    :is-selected="isSelected"
    :options="options"
    :validation-result="validationResult"
    data-component-name="VSelect"
    @select="select"
    @add="add"
  >
    <slot name="display">
      <template v-if="selectedOption">
        <VIcon v-if="selectedOption.icon" dense class="mr-2" :name="selectedOption.icon" />
        {{ selectedOption.label ?? selectedOption.value }}
      </template>
      <template v-else>
        <span class="text-neutral-400" v-text="props.placeholder || '&nbsp;'" />
      </template>
    </slot>
    <VIcon dense class="ml-auto" color="neutral" name="Solid/chevron-down" />
  </VSSelectBase>
</template>
<script lang="ts" generic="T extends string | number" setup>
import { computed, ref } from 'vue';
import VIcon from './../labels/VIcon.vue'
import VSSelectBase from './components/VSSelectBase.vue'
import type { ValidationResult } from '../../utils/validations';
import { findSelectOption } from './helpers/select';
import type { V } from '@component-utils/types';

defineOptions({
  name: 'VSelect'
})

const props =  defineProps<{
  options: V.Select.Option<T>[]
  id?: string
  label?: string
  description?: string
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  search?: boolean
  searchPlaceholder?: string
  open?: boolean
  validator?: (value: undefined | null | T) => ValidationResult
}>()

const modelValue = defineModel<undefined | null | T>({ required: true })

const validationActive = ref(false)
const validationResult = computed(() => props.validator && validationActive.value && props.validator(modelValue.value) || null)

const isSelected = (option: V.Select.Item<T>) => modelValue.value === option.value

const options = computed(() => {
  const value = modelValue.value

  if (value && !findSelectOption(value, 'value', props.options)) {
    return [
      {
        value
      } as V.Select.Option<T>,
      ...props.options
    ]
  } else {
    return props.options
  }
})

const selectedOption = computed(() => findSelectOption(modelValue.value, 'value', props.options))

const select = (value: T) => {
  modelValue.value = value

  validationActive.value = true
}

const add = (value: T) => {
  modelValue.value = value
}
</script>